import { useQuery } from '@tanstack/react-query';
import { CommunitiesSidePanelResponseData, UserCommunityParamsType } from '@/types';
import { getCommunitySidePanelDetails } from '@/services/communities.service';

const useGetCommunitySidePanelQuery = ({
  PageSize = 5,
  PageNumber = 1,
  ...rest
}: UserCommunityParamsType) => {
  return useQuery<CommunitiesSidePanelResponseData[]>({
    queryKey: ['GetCommunitySidePanelData', { PageSize, ...rest }],
    queryFn: () =>
      getCommunitySidePanelDetails({
        PageSize,
        PageNumber: rest.Search?.trim() ? 1 : PageNumber,
        ...rest,
      }),
  });
};
export default useGetCommunitySidePanelQuery;
